<template>
  <v-app>
    <div>
      <v-alert
        v-model="alert"
        border="left"
        dark
        dismissible
        class="alert"
        :class="{
          'alert-error': alertType == 'error',
          'alert-success': alertType == 'success'
        }"
      >
        {{ alertMessage }}
      </v-alert>
    </div>
    <div class="row">
      <div class="col-md-6 ml-auto mr-auto">
        <div class="mb-8 mt-8 text-center">
          <h1>Training Request</h1>
        </div>
        <v-form ref="ppeRequest" v-model="valid">
          <v-row>
            <v-col>
              <SelectField
                :items="trainingType"
                v-model="formData.trainingTypeId"
                :label="'Training Type'"
                :itemText="'name'"
                :itemValue="'id'"
                :returnObject= true
              />
            </v-col>
            <v-col cols="6" class="pr-1">
              <SelectField
                :items="trainingLocation"
                :label="'Training Location'"
                v-model="formData.location"
                :itemText="'name'"
                :itemValue="'id'"
              />
            </v-col>
            <v-col cols="6" class="pl-1">
              <TextField
                :type="'text'"
                :label="'Trainer'"
                v-model="formData.trainer"
              />
            </v-col>
            <v-col :class="personnel.length > 0 ? 'mb-1':'mb-6'">
              <!-- <TextField v-model="formData.personnel" :label="'Personnel'" /> -->
              <v-btn
                class="btn-style justify-start text-style"
                @click.prevent="perSearch = true"
                width="100%"
                height="38"
                outlined
              >
                <v-icon left>mdi-magnify</v-icon> Personnel</v-btn
              >
            </v-col>
            <!-- selected personnel list -->
            <v-col v-if="personnel.length > 0" class="mb-6">
              <v-data-table
                :headers="selectedPersonnelHeader"
                :items="personnel"
                :hide-default-footer="true"
              >
                <template v-slot:item.actions="{ item }">
                  <v-icon small @click="removePersonnel(item)">
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-col>

            <v-col cols="6" class="pr-1">
              <v-text-field
                v-model="formData.startDate"
                type="date"
                label="Start Date"
                :max="formData.endDate"
                dense
                outlined
                oninput="(!validity.rangeOverflow||(value=24)) && (!validity.rangeUnderflow||(value=0)) && (!validity.stepMismatch||(value=parseInt(this.value)));"
              />
            </v-col>
            <v-col cols="6" class="pl-1">
              <v-text-field
                v-model="formData.endDate"
                type="date"
                label="End Date"
                :min="formData.startDate"
                dense
                outlined
                onkeydown="(!validity.rangeOverflow||(value=24)) && (!validity.rangeUnderflow||(value=0)) && (!validity.stepMismatch||(value=parseInt(this.value)));"
              />
            </v-col>
            <v-col>

              <AmountInput v-model="formData.amount"  v-bind="{label: formData.location === 2 ? 'Estimated Amount ($)': 'Estimated Amount (N)'}"  />

            </v-col>

            <v-col cols="6" class="pr-1">
              <v-file-input
              class="file-decoration"
                label="Description"
                outlined
                dense
              ></v-file-input>
            </v-col>
            <v-col cols="6" class="pl-1">
              <v-file-input

                label="Description"
                outlined
                dense
              ></v-file-input>
            </v-col>

            <v-col>
              <TextField
                v-model="formData.shortDescription"
                :type="'text'"
                :label="'Short Description'"
              />
            </v-col>
            <v-col>
              <v-textarea
                outlined
                name="input-7-4"
                label="Long Description"
                v-model="formData.longDescription"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
        <v-col cols="12" class="d-flex justify-end">
          <Button
            :label="'Generate Request'"
            :btnType="'Submit'"
            :disabled="!valid"
            @onClick="generateRequest"
            :isLoading="creating"
            class=""
          />
        </v-col>
      </div>
    </div>
    <v-row>
      <div class="col-md-10 col-sm-12 ml-auto mr-auto">
        <v-col v-if="request.length > 0" class="mb-6">
        <v-data-table :headers="requestHeader" :items="request">
          <template v-slot:item.comments="{ item }">
            <input type="text" class="input" v-model="item.comment" />
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon small @click="removeRequest(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
      </div>
    </v-row>

    <!-- Personnel Search Modal -->
    <Dialog ref="personnelSearch" :title="'Personnel Search'" :width="600">
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="search"
            outlined
            label="Search"
            @blur="personnelSearchFunction"
            dense
          />
        </v-col>
        <v-col v-if="loadingPersonnelData" cols="12">
          <div class="text-center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
        </v-col>
        <v-col v-else cols="12">
          <v-data-table
            dense
            show-select
            v-model="personnel"
            item-key="id"
            @click:row="selectPersonnel"
            :headers="searchHeaders"
            :items="personnelSearch"
            class="elevation-1 row-pointer"
            :hide-default-footer="true"
          ></v-data-table>
        </v-col>
      </v-row>
      <template v-slot:footer>
        <Button
          :label="'Close'"
          :btnType="'Cancel'"
          @onClick="closePersonnelSearchModal"
        />
      </template>
    </Dialog>
    <v-row>
      <v-col cols="9 mt-5" class="d-flex justify-end">
        <Button
          :label="'Submit'"
          :btnType="'Submit'"
          @onClick="dialog = true"
          :disabled="!valid"
          :isLoading="isSaving"
          class="mr-4"
        />

        <Button :label="'Reset'" :btnType="'Cancel'" @onClick="onReset" />
      </v-col>
    </v-row>
     <ConfirmationDialog :title="'Are you sure you want to submit?'" :isLoading="isSaving" :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="onSave"/>
     <PersonnelSearch :dialog="perSearch" @selectPer="selectPersonnel"  @close="perSearch = false"/>
  </v-app>
</template>
<script>
import TextField from '@/components/ui/form/TextField.vue'
import Dialog from '@/components/ui/Dialog.vue'
import Button from '@/components/ui/form/Button.vue'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import SelectField from '@/components/ui/form/SelectField.vue'
import AmountInput from '@/components/ui/form/AmountInputField.vue'
import { employeeService, requestService } from '@/services'
import { CREATE_REQUEST } from '@/store/action-type'
import PersonnelSearch from '@/components/ui/PersonnelSearchModal.vue'

export default {
  components: {
    TextField,
    Dialog,
    Button,
    SelectField,
    AmountInput,
    ConfirmationDialog,
    PersonnelSearch
  },
  data () {
    return {
      creating: false,
      alertMessage: '',
      alert: false,
      alertType: '',
      ItemType: null,
      dialog: false,
      perSearch: false,
      selected: [],
      search: '',
      valid: false,
      isSaving: false,
      training: '',
      formData: {
        trainingTypeId: '',
        training: '',
        RequestTypeId: 6,
        startDate: '',
        endDate: '',
        shortDescription: '',
        amount: 0,
        longDescription: '',
        location: '',
        trainer: ''
      },
      request: [],

      trainingType: [],

      personnelSearch: [],

      ppeTypes: [],
      searchHeaders: [
        {
          text: 'FullName',
          value: 'name'
        },
        {
          text: 'id',
          value: 'id'
        },
        {
          text: 'email',
          value: 'email'
        }
      ],
      selectedPersonnelHeader: [
        {
          text: 'Name',
          value: 'name'
        },
        { text: 'Actions', value: 'actions' }
      ],
      requestHeader: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Training',
          value: 'training'
        },
        {
          text: 'Trainer',
          value: 'trainer'
        },
        {
          text: 'Cost',
          value: 'amount'
        },
        {
          text: 'StartDate',
          value: 'startDate'
        },
        {
          text: 'EndDate',
          value: 'endDate'
        },
        {
          text: 'shortDescription',
          value: 'shortDescription'
        },
        { text: 'Comment', value: 'comments' },
        { text: 'Actions', value: 'actions' }
      ],
      personnel: [],
      loadingPersonnelData: false,
      trainingLocation: [{ id: 1, name: 'Local' }, { id: 2, name: 'Foreign ' }]
    }
  },
  methods: {
    openPersonnelSearchModal () {
      this.$refs.personnelSearch.openModal()
    },
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
    },

    closePersonnelSearchModal () {
      this.personnelSearch = []
      this.$refs.personnelSearch.closeModal()
    },
    personnelSearchFunction () {
      if (this.search === '') {
        this.personnelSearch = []
        return
      }

      this.loadingPersonnelData = true

      employeeService.searchPersonnel(this.search).then(result => {
        this.personnelSearch = result.data
      }).catch(() => {
        this.showAlertMessage('Failed to load Personnnel', 'error')
      }).finally(() => {
        this.loadingPersonnelData = false
      })
    },
    selectPersonnel (item) {
      console.log(item)
      let index = null
      for (let i = 0; i < this.personnel.length; i++) {
        if (this.personnel[i].id === item.id) {
          index = i
          break
        }
      }
      if (index === 0) {
        return
      }

      this.personnel.push({ name: `${item.name}`, id: item.id })
      this.search = ''
    },
    removePersonnel (item) {
      let index = null
      for (let i = 0; i < this.personnel.length; i++) {
        if (this.personnel[i].id === item.id) {
          index = i
          break
        }
      }
      console.log(index)
      this.personnel.splice(index, 1)
    },
    removeRequest (item) {
      let index = null
      for (let i = 0; i < this.request.length; i++) {
        if (this.request[i].id === item.id) {
          index = i
          break
        }
      }
      console.log(index)
      this.request.splice(index, 1)
    },
    generateRequest () {
      this.personnel.forEach(per => {
        this.request.push({
          name: per.name,
          id: per.id,
          training: this.formData.trainingTypeId.name,
          trainer: this.formData.trainer,
          trainingTypeId: this.formData.trainingTypeId,
          startDate: this.formData.startDate,
          endDate: this.formData.endDate,
          amount: this.formData.amount,
          shortDescription: this.formData.shortDescription,
          longDescription: this.formData.longDescription,
          comment: per.comment
        })
      })

      console.log(this.request)
    },
    onSave () {
      let data = []
      this.request.map(req => {
        return data.push({
          EmployeeId: req.id,

          RequestTypeId: 6,
          Training: req.trainingTypeId.name,
          Amount: parseInt(req.amount),
          StartDate: req.startDate,
          ResumptionDate: req.endDate,
          TrainingTypeId: req.trainingTypeId.id,
          LeaveDays: 1,
          Comments: req.comment,
          shortDescription: req.shortDescription,
          longDescription: req.longDescription
        })
      })
      this.isSaving = true

      this.$store
        .dispatch(CREATE_REQUEST, { type: 'training', data })
        .then(() => {
          this.showAlertMessage('Operation successful', 'success')
        })
        .catch(error => {
          console.log(error)
          this.showAlertMessage('Operation failed', 'error')
        })
        .finally(() => {
          this.isSaving = false
          this.dialog = false
          this.onReset()
        })
    },
    onReset () {
      this.personnel = []
      this.$refs.ppeRequest.reset()
    }
  },
  mounted () {
    requestService
      .getPpeTypes()
      .then(result => {
        this.ppeTypes = result.data
      })
      .catch(error => {
        console.log(error)
      })
    requestService
      .getTrainingType()
      .then(result => {
        this.trainingType = result.data
      })
      .catch(error => {
        console.log(error)
      })
  }
}
</script>
<style scoped>
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.btn-style {
  color: rgba(0, 0, 0, 0.6);
  text-align: left;
}
.text-style {
  text-transform: capitalize;
}
.input {
  border: 1px solid grey;
  height: 30px;
  padding-left: 10px;
}
.file-decoration .v-icon .notranslate .v-icon--link {
  background-color: #e0e0e0 !important;
    height: 39px;
    margin: 1px !important;
    padding: 7px;
    border-radius: 5px;
}
</style>
